.statements-table .MuiTable-root {
    table-layout: auto!important;
}

.statements-table .MuiTableCell-head {
    padding: 6px 16px;
    vertical-align: bottom;
}

.statements-table .MuiTableCell-alignRight {
    text-align: left;
}

.statements-table th:first-child,
.statements-table td:first-child,
.statements-table th:nth-child(2),
.statements-table td:nth-child(2) {
    position: sticky;
    left: 0;
    z-index: 11;
    background-color: #FFF;
}

.statements-table th:nth-child(2),
.statements-table td:nth-child(2) {
    position: sticky;
    left: 106px;
    z-index: 11;
    background-color: #FFF;
}
